/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  CardContent,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomField from 'components/Form/Input/CustomField';
import {
  customInputStyles,
  ErrorDiv,
} from 'components/Form/Input/CustomInput.style';
import { Roles } from 'enums/roles';
import { createForm, FormApi, Unsubscribe } from 'final-form';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { showError } from 'store/notify/notify.actions';
import { IShowErrorPayload } from 'store/notify/notify.actions.types';
import {
  addSpace,
  changeCredentials,
  initializationCheck,
  initializeContentful,
} from 'store/settings/settings.actions';
import { SettingsAuthorization } from 'store/settings/settings.interface';
import {
  getCurrentOrganizationSettingsSelector,
  getSettingsErrorsSelector,
  getSettingsSelector,
  isInitializedSelector,
  setAddSpaceSelector,
  setClientIdSelector,
} from 'store/settings/settings.selectors';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { clearUserErrors } from 'store/user/user.actions';
import { getUserDataSelector } from 'store/user/user.selectors';
import { checkRoles } from 'utils/checkRoles';
import {
  composeValidators,
  fetchValidatorObject,
  required,
} from 'utils/customValidators';
import {
  AuthenticationResult,
  OrganizationSettingsDTO,
  SettingsDTO,
} from 'utils/restApplicationClient';
import {
  CentredContainer,
  CustomConnectionCard,
  CustomTitleCard,
  Tittle,
} from './SettingsContainer.style';

interface IDispatchProps {
  login: (payload: SettingsAuthorization) => AppDispatch;
  changeCredentials: (payload: SettingsAuthorization) => AppDispatch;
  initializationCheck: () => AppDispatch;
  clearUserErrors: () => AppDispatch;
  setAddSpace: () => AppDispatch;
  showErrors: (payload: IShowErrorPayload) => AppDispatch;
}

interface IStateProps {
  errors: { [key: string]: string | undefined };
  currentUser: AuthenticationResult;
  fetchSpinner: boolean;
  settings: SettingsDTO[];
  canAddSpace: boolean;
  isInitialized: boolean;
  selectedSettings?: OrganizationSettingsDTO;
  clientId?: string;
}

interface IFormValues extends Record<string, unknown> {
  connectionSpaceId: string;
  cmaToken: string[];
}

interface ISettingsProps {
  fetchSpinnerOverride?: boolean;
  spaceId?: string;
  initialValue?: string;
}

interface IState {
  submitValues: IFormValues;
}

type IProps = IDispatchProps &
  IStateProps &
  WithTranslation &
  RouteComponentProps &
  ISettingsProps;

class SettingsContainer extends Component<IProps, IState> {
  unsubscribe: Unsubscribe;
  constructor(props: IProps) {
    super(props);
    this.form = createForm({
      onSubmit: this.onSubmit,
    });
    this.unsubscribe = this.form.subscribe(
      () => {
        if (this.props.errors['initialization']) {
          this.props.clearUserErrors();
        }
      },
      { values: true },
    );

    this.state = {
      submitValues: {
        connectionSpaceId: '',
        cmaToken: [''],
      },
    };
  }
  form: FormApi<IFormValues, Partial<Record<string, unknown>>>;

  onSubmit = ({ connectionSpaceId, cmaToken }: IFormValues): void => {
    const { currentUser, location, login, changeCredentials, clientId } =
      this.props;
    const isClientAdmin = checkRoles(
      [Roles.ADMIN_XTM],
      currentUser.roles as Roles[],
    );

    this.setState(
      {
        submitValues: {
          connectionSpaceId,
          cmaToken,
        },
      },
      () => {
        if (location.pathname === '/settings/connect' && !isClientAdmin) {
          login({
            connectionSpaceId,
            cmaToken,
          });
        } else if (isClientAdmin) {
          login({
            clientId,
            connectionSpaceId,
            cmaToken,
          });
        } else {
          changeCredentials({
            connectionSpaceId,
            cmaToken,
          });
        }
      },
    );
  };

  componentDidMount(): void {
    const { currentUser, canAddSpace, history, clientId } = this.props;
    const isClientAdmin = checkRoles(
      [Roles.ADMIN_XTM],
      currentUser.roles as Roles[],
    );

    if (!isClientAdmin && !canAddSpace) {
      this.props.initializationCheck();
    }

    if (!clientId && isClientAdmin) {
      history.goBack();
    }
  }
  componentDidUpdate(): void {
    const { history, isInitialized } = this.props;
    if (isInitialized) {
      history.push(`/settings/connect/successful`);
    }

    // if (errors) {
    //   errors.map((data) => {
    //     showError(data);
    //   });
    // }
  }

  componentWillUnmount(): void {
    this.props.setAddSpace();
    this.unsubscribe();
  }

  initialValue = (): string | undefined => {
    const { location, selectedSettings } = this.props;
    if (location.pathname === '/settings/reconnect') {
      return selectedSettings?.spaceId;
    }
    return undefined;
  };

  onChangingCredentials = (): boolean => {
    const { location } = this.props;
    if (location.pathname === '/settings/reconnect') {
      return true;
    }
    return false;
  };

  renderEmpty = (): JSX.Element => {
    const { fetchSpinner } = this.props;
    if (fetchSpinner) {
      return <CircularProgress color="secondary" size={120} />;
    }
    return <div></div>;
  };
  render(): JSX.Element {
    const {
      t,
      fetchSpinner,
      errors,
      selectedSettings,
      canAddSpace,
      history,
      settings,
    } = this.props;

    const formError = errors['initialization'];

    // TODO
    const onBack = (): void => {
      if (settings.length === 0) {
        history.push(`/settings`);
      } else {
        history.goBack();
      }
    };
    return (
      <CentredContainer>
        <CustomConnectionCard variant="outlined">
          <CustomTitleCard>
            {canAddSpace && (
              <IconButton size="small" onClick={(): void => onBack()}>
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
            )}
            <Tittle>{t('auth.settings.tittle')}</Tittle>
          </CustomTitleCard>
          <CardContent>
            {fetchSpinner ? (
              this.renderEmpty()
            ) : (
              <Form
                onSubmit={this.onSubmit}
                form={this.form}
                subscription={{ pristine: true, submitting: true }}
                validate={fetchValidatorObject(
                  formError,
                  this.state.submitValues,
                )}
                render={({ handleSubmit, submitting }): JSX.Element => (
                  <form onSubmit={handleSubmit}>
                    {selectedSettings ? (
                      <Field
                        name="connectionSpaceId"
                        validate={composeValidators([required])}
                        initialValue={this.initialValue()}
                      >
                        {({ input, meta }): JSX.Element => (
                          <CustomField
                            label="common.spaceId"
                            {...meta}
                            inputProps={{ ...input }}
                            disabled={this.onChangingCredentials()}
                          />
                        )}
                      </Field>
                    ) : (
                      <Field
                        name="connectionSpaceId"
                        validate={composeValidators([required])}
                      >
                        {({ input, meta }): JSX.Element => (
                          <CustomField
                            label="common.spaceId"
                            {...meta}
                            inputProps={{ ...input }}
                          />
                        )}
                      </Field>
                    )}

                    <Field
                      name="cmaToken"
                      validate={composeValidators([required])}
                    >
                      {({ input, meta }): JSX.Element => (
                        <CustomField
                          label="common.cmaToken"
                          {...meta}
                          inputProps={{ ...input }}
                          disableAutoComplete={true}
                        />
                      )}
                    </Field>
                    <ErrorDiv>
                      <span css={customInputStyles.textErrorNoMargin}>
                        {formError ? t(formError) : null}
                      </span>
                    </ErrorDiv>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={submitting || fetchSpinner}
                      type="submit"
                    >
                      {t('auth.settings.connect')}
                    </Button>
                  </form>
                )}
              />
            )}
          </CardContent>
        </CustomConnectionCard>
      </CentredContainer>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  login: (payload: SettingsAuthorization): AppDispatch =>
    dispatch(initializeContentful(payload)),
  changeCredentials: (payload: SettingsAuthorization): AppDispatch =>
    dispatch(changeCredentials(payload)),
  clearUserErrors: (): AppDispatch => dispatch(clearUserErrors()),
  initializationCheck: (): AppDispatch => dispatch(initializationCheck()),
  setAddSpace: (): AppDispatch => dispatch(addSpace(false)),
  showErrors: (payload: IShowErrorPayload): AppDispatch =>
    dispatch(showError(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getSettingsErrorsSelector(state),
  fetchSpinner: getFetchSpinnerSelector(state),
  settings: getSettingsSelector(state),
  currentUser: getUserDataSelector(state),
  canAddSpace: setAddSpaceSelector(state),
  isInitialized: isInitializedSelector(state),
  selectedSettings: getCurrentOrganizationSettingsSelector(state),
  clientId: setClientIdSelector(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(SettingsContainer)),
);
